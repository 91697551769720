import { render, staticRenderFns } from "./WithholdingAdd.vue?vue&type=template&id=31269a32&scoped=true"
import script from "./WithholdingAdd.vue?vue&type=script&lang=js"
export * from "./WithholdingAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31269a32",
  null
  
)

export default component.exports